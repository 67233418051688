<div class="form-group row" *ngIf="event.eventType === 'shot'">
  <label class="col-sm-2 col-form-label">Shot outcome</label>
  <div class="col-sm-4">
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="so-1"
    >
      <input
        type="radio"
        id="so-1"
        class="mdl-radio__button"
        name="so-options"
        [(ngModel)]="event.shotOutcome"
        value="miss"
      />
      <span class="mdl-radio__label">Miss</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="so-2"
    >
      <input
        type="radio"
        id="so-2"
        class="mdl-radio__button"
        name="so-options"
        [(ngModel)]="event.shotOutcome"
        value="blocked"
      />
      <span class="mdl-radio__label">Blocked</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="so-3"
    >
      <input
        type="radio"
        id="so-3"
        class="mdl-radio__button"
        name="so-options"
        [(ngModel)]="event.shotOutcome"
        value="goal"
      />
      <span class="mdl-radio__label">Goal</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="so-4"
    >
      <input
        type="radio"
        id="so-4"
        class="mdl-radio__button"
        name="so-options"
        [(ngModel)]="event.shotOutcome"
        value="on_goal"
      />
      <span class="mdl-radio__label">On Goal</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="so-5"
    >
      <input
        type="radio"
        id="so-5"
        class="mdl-radio__button"
        name="so-options"
        [(ngModel)]="event.shotOutcome"
        value="iron"
      />
      <span class="mdl-radio__label">Iron</span>
    </label>
  </div>
</div>
<div class="form-group row">
  <ng-container
    *ngIf="game?.isAssistsCollection && event.shotOutcome === 'goal'"
  >
    <label class="col-sm-2 col-form-label" for="assist1">Primary Assist</label>
    <div class="col-sm-4">
      <select
        id="assist1"
        [(ngModel)]="event.assist1"
        name="assist1"
        class="form-control"
      >
        <option *ngFor="let p of players" selected="event.assist1 == p">{{
          p
        }}</option>
      </select>
    </div>

    <label class="col-sm-2 col-form-label" for="assist2">Second Assist</label>
    <div class="col-sm-4">
      <select
        id="assist2"
        [(ngModel)]="event.assist2"
        name="assist2"
        class="form-control"
      >
        <option *ngFor="let p of players" selected="event.assist2 == p">{{
          p
        }}</option>
      </select>
    </div>

    <div class="col-sm-2"></div>
    <div class="col-sm-2">
      <button
        mat-button
        color="primary"
        style="display: inline-block"
        (click)="deduceAssists()"
      >
        Deduce Assists
      </button>
    </div>
    <div class="col-sm-8"></div>
  </ng-container>

  <ng-container *ngIf="event.shotOutcome === 'blocked'">
    <label class="col-sm-2 col-form-label">Blocker</label>
    <div class="col-sm-4">
      <select [(ngModel)]="event.blocker" name="blocker" class="form-control">
        <option
          *ngFor="let p of opponentPlayers"
          selected="event.blocker == p"
          >{{ p }}</option
        >
      </select>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      event.shotOutcome === 'goal' ||
      event.shotOutcome === 'on_goal' ||
      event.shotOutcome === 'iron'
    "
  >
    <label class="col-sm-2 col-form-label">Shot Impact</label>
    <div class="col-sm-4">
      <span>{{ event.netImpactX }} / {{ event.netImpactY }}</span>
      <button
        mat-button
        color="primary"
        style="display: inline-block"
        (click)="showShotImpactDialog()"
        >Show Goal</button
      >
    </div>
  </ng-container>

  <label class="col-sm-2 col-form-label">Shot scenario</label>
  <div class="col-sm-4">
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ss-1"
    >
      <input
        type="radio"
        id="ss-1"
        class="mdl-radio__button"
        name="ss-options"
        [(ngModel)]="event.shotScenario"
        value="regular"
      />
      <span class="mdl-radio__label">Regular</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ss-2"
    >
      <input
        type="radio"
        id="ss-2"
        class="mdl-radio__button"
        name="ss-options"
        [(ngModel)]="event.shotScenario"
        value="one_timer"
      />
      <span class="mdl-radio__label">One Timer</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ss-3"
    >
      <input
        type="radio"
        id="ss-3"
        class="mdl-radio__button"
        name="ss-options"
        [(ngModel)]="event.shotScenario"
        value="rebound"
      />
      <span class="mdl-radio__label">Rebound</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="ss-4"
    >
      <input
        type="radio"
        id="ss-4"
        class="mdl-radio__button"
        name="ss-options"
        [(ngModel)]="event.shotScenario"
        value="quick_release"
      />
      <span class="mdl-radio__label">Quick release</span>
    </label>
  </div>

  <label class="col-sm-2 col-form-label">Shot type</label>
  <div class="col-sm-4">
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="st-1"
    >
      <input
        type="radio"
        id="st-1"
        class="mdl-radio__button"
        name="st-options"
        [(ngModel)]="event.shotType"
        value="wrist_shot"
      />
      <span class="mdl-radio__label">Wrist Shot</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="st-2"
    >
      <input
        type="radio"
        id="st-2"
        class="mdl-radio__button"
        name="st-options"
        [(ngModel)]="event.shotType"
        value="slap_shot"
      />
      <span class="mdl-radio__label">Slap Shot</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="st-3"
    >
      <input
        type="radio"
        id="st-3"
        class="mdl-radio__button"
        name="st-options"
        [(ngModel)]="event.shotType"
        value="backhand_shot"
      />
      <span class="mdl-radio__label">Backhand Shot</span>
    </label>
    <label
      class="radio-inline mdl-radio mdl-js-radio mdl-js-ripple-effect"
      for="st-4"
    >
      <input
        type="radio"
        id="st-4"
        class="mdl-radio__button"
        name="st-options"
        [(ngModel)]="event.shotType"
        value="deflection"
      />
      <span class="mdl-radio__label">Deflection</span>
    </label>
  </div>

  <label class="col-sm-2 col-form-label">Shot details</label>
  <div class="col-sm-4">
    <label for="chkbox_net_traffic" class="mdl-checkbox mdl-js-checkbox">
      <input
        type="checkbox"
        id="chkbox_net_traffic"
        class="mdl-checkbox__input"
        [checked]="event.has_net_traffic"
        (change)="onNetTrafficChange(!event.has_net_traffic)"
      />
      <span class="mdl-checkbox__label">Net Traffic</span>
    </label>
    <label for="chkbox_screen" class="mdl-checkbox mdl-js-checkbox">
      <input
        type="checkbox"
        id="chkbox_screen"
        name="chkbox_screen"
        class="mdl-checkbox__input"
        [checked]="event.has_screen"
        (change)="onScreenerChange(!event.has_screen)"
      />
      <span class="mdl-checkbox__label">Screen</span>
    </label>
  </div>

  <label class="col-sm-2 col-form-label">Empty Net</label>
  <div class="col-sm-4">
    <label for="checkbox_home_empty_net" class="mdl-checkbox mdl-js-checkbox">
      <input
        id="checkbox_home_empty_net"
        name="checkbox_home_empty_net"
        type="checkbox"
        class="mdl-checkbox__input"
        [checked]="event.isHomeTeamEmptyNet"
        (change)="event.isHomeTeamEmptyNet = !event.isHomeTeamEmptyNet"
      />
      <span class="mdl-checkbox__label">Home Team Empty Net</span>
    </label>
    <label for="checkbox_away_empty_net" class="mdl-checkbox mdl-js-checkbox">
      <input
        id="checkbox_away_empty_net"
        name="checkbox_away_empty_net"
        class="mdl-checkbox__input"
        type="checkbox"
        [checked]="event.isAwayTeamEmptyNet"
        (change)="event.isAwayTeamEmptyNet = !event.isAwayTeamEmptyNet"
      />
      <span class="mdl-checkbox__label">Away Team Empty Net</span>
    </label>
  </div>

  <ng-container *ngIf="event.shotType === 'deflection'">
    <label class="col-sm-2 col-form-label">Deflector</label>
    <div class="col-sm-4">
      <select [(ngModel)]="event.deflector" class="form-control">
        <option *ngFor="let p of players" selected="event.deflector == p">{{
          p
        }}</option>
      </select>
    </div>
  </ng-container>
  <ng-container *ngIf="event.has_net_traffic === true">
    <label class="col-sm-2 col-form-label">Net Traffic Causer</label>
    <div class="col-sm-4">
      <select [(ngModel)]="event.net_traffic_causer" class="form-control">
        <option
          *ngFor="let p of players"
          selected="event.net_traffic_causer == p"
          >{{ p }}</option
        >
      </select>
    </div>
  </ng-container>
  <ng-container *ngIf="event.has_screen === true">
    <label class="col-sm-2 col-form-label">Screener</label>
    <div class="col-sm-4">
      <select [(ngModel)]="event.screener" class="form-control">
        <option *ngFor="let p of players" selected="event.screener == p">{{
          p
        }}</option>
      </select>
    </div>
  </ng-container>

  <ng-container *ngIf="hasGameGoalClips && event.shotOutcome === 'goal'">
    <label class="col-sm-2 col-form-label">Goal Slow-Motion</label>
    <div class="col-sm-4 ing-goal-clip-status-container">
      <div class="ing-mark-col">
        <div
          *ngIf="
            !(
              event.goalClip &&
              event.goalClip.startVideoTime &&
              event.goalClip.endVideoTime
            )
          "
        >
          <h6 class="ing-status-title">
            <mat-icon
              title="Missing goal clip time"
              matTooltip="Not marked"
              *ngIf="
                !(
                  event.goalClip &&
                  event.goalClip.startVideoTime &&
                  event.goalClip.endVideoTime
                )
              "
              class="goal-clip-icon"
              >warning</mat-icon
            >
            Not marked
          </h6>
        </div>
        <div
          *ngIf="
            event.goalClip &&
            event.goalClip.startVideoTime &&
            event.goalClip.endVideoTime
          "
        >
          <h6 class="ing-status-title">
            <mat-icon
              title="Goal clip complete"
              matTooltip="Goal clip marked"
              class="goal-clip-icon"
              >done</mat-icon
            >
            Marked
          </h6>
        </div>
        <button
          mat-button
          color="primary"
          (click)="markSlowMotionGoalClip(event?.goalClip)"
          >Mark Slow-Motion</button
        >
        <button
          *ngIf="event.goalClip?.startVideoTime"
          mat-button
          color="warn"
          (click)="unmarkSlowMotionGoalClip()"
          >Unmark Slow-Motion</button
        >
      </div>

      <div class="ing-upload-status-col" *ngIf="event?.goalClip">
        <h6
          *ngIf="event?.goalClip?.exportStatus?.status"
          matTooltip="Export to Vimeo"
          class="ing-status-title"
          >{{ exportStatus(event?.goalClip?.exportStatus) }}
        </h6>
        <button
          [disabled]="canExportClip(event) === false"
          (click)="exportGameEvent(event)"
          mat-button
        >
          <mat-icon style="margin-right: 5px">cloud_upload</mat-icon>
          <span *ngIf="canExportClip(event) && event.goalClip.vimeoVideoId"
            >Re-</span
          >
          Export
        </button>
        <a
          mat-button
          *ngIf="event?.goalClip?.vimeoVideoId"
          [href]="vimeoUrl(event?.goalClip?.vimeoVideoId)"
          target="_blank"
        >
          <mat-icon>link</mat-icon>
          Vimeo
        </a>
      </div>
    </div>
  </ng-container>
</div>
