import { Location } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import ObjectId from 'bson-objectid';
import { NGXLogger } from 'ngx-logger';
import { Subject, interval, firstValueFrom } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { BroadcastAction, BroadcastService } from '../broadcast.service';
import {
  CameraAngle,
  Game,
  Video,
  VideoFormat,
  VideoVariantType
} from '../domain/game';
import {
  EventType,
  GameEvent,
  GameIncidentReason,
  GamePeriod,
  GoalClip,
  HighlightRating,
  InterruptionType,
  OddMenRushDetail,
  OfficialsCallAction,
  OfficialsCallAssessment,
  OfficialsPenaltyCallSeverity,
  OfficialsUncalledPenaltySeverity,
  PenaltyDuration,
  StrengthState
} from '../domain/game-event';
import { Player } from '../domain/player';
import { Players } from '../domain/players';
import { Shift } from '../domain/shift';
import { Team } from '../domain/team';
import { EventsComponent } from '../events/events.component';
import { AlertService } from '../services/alert.service';
import { EventValidationService } from '../services/event-validation.service';
import { EventService } from '../services/event.service';
import { GameTimeService } from '../services/game-time.service';
import { GameService } from '../services/game.service';
import { PuckPossessionStateService } from '../services/puck-possession-state.service';
import { ShiftsService } from '../services/shifts.service';
import { StrengthStateService } from '../services/strength-state.service';
import { VideoService } from '../services/video.service';
import { CameraAnglesSelectDialogComponent } from '../shared/camera-angles-select/camera-angles-select-dialog.component';
import { PlayerSummaryComponent } from '../shared/player-summary/player-summary.component';
import { VideoPlayerTrimDialogComponent } from '../video-player-trim/video-player-trim-dialog/video-player-trim-dialog.component';

import {
  assist1PlayerNumberChange,
  assist2PlayerNumberChange,
  deflectorPlayerNumberChange,
  eventIdChange,
  eventTypeChange,
  faceoffOpponentPlayerNumberChange,
  faceoffOutcomePositionChange,
  fouledPlayerNumberChange,
  gameIncidentReasonChange,
  hasNetTrafficChange,
  hasScreenChange,
  highlightPlaybackChange,
  highlightRatingChange,
  highlightTypeChange,
  interruptionTypeChange,
  netTrafficCauserPlayerNumberChange,
  oddMenRushDetailChange,
  passOutcomeChange,
  passReceiverPlayerNumberChange,
  passReceiverPositionChange,
  passTypeChange,
  periodChange,
  playerNumberChange,
  positionChange,
  resetHardState as resetHardStateGameEvent,
  resetState as resetStateGameEvent,
  screenerPlayerNumberChange,
  shotBlockerPlayerNumberChange,
  shotBlockerPositionChange,
  shotOutcomeChange,
  shotScenarioChange,
  shotTypeChange,
  teamChange,
  teamFaceoffOutcomeChange,
  videoTagChange
} from '../state/actions/game-event.action';
import {
  gameIdChange,
  resetHardState as resetHardStateGame,
  resetState as resetStateGame,
  videoTimeExternalChange
} from '../state/actions/game.action';
import { GlobalState } from '../state/reducers';
import {
  selectAssist1PlayerNumber,
  selectAssist2PlayerNumber,
  selectDeflectorPlayerNumber,
  selectEventType,
  selectFaceOffOpponentPlayerNumber,
  selectFaceoffOutcomePosition,
  selectFouledPlayerNumber,
  selectGameEventState,
  selectGameIncidentReason,
  selectGameTime,
  selectHas_net_traffic,
  selectHas_screen,
  selectHighlightPlayback,
  selectHighlightRating,
  selectHighlightType,
  selectInterruption_type,
  selectNetImpact,
  selectNetTrafficCauserPlayerNumber,
  selectOddMenRushDetail,
  selectPassOutcome,
  selectPassReceiverPlayerNumber,
  selectPassReceiverPosition,
  selectPassType,
  selectPenaltyDuration,
  selectPenaltyId,
  selectPenaltyType,
  selectPeriod,
  selectPlayerNumber,
  selectPosition,
  selectScreenerPlayerNumber,
  selectShotBlockerPlayerNumber,
  selectShotBlockerPosition,
  selectShotOutcome,
  selectShotScenario,
  selectShotType,
  selectStrengthState,
  selectTeam,
  selectTeamFaceOffOutcome,
  selectVideoTag,
  selectVideoTime
} from '../state/reducers/game-event.reducer';
import { selectGameId, selectGameState } from '../state/reducers/game.reducer';
import { GameEventInterruptionTypeService } from '../services/game-event-interruption-type.service';
import { GameIncidentService } from '../services/game-incident.service';

@Component({
  selector: 'app-game-events',
  templateUrl: './game-events.component.html',
  styleUrls: ['./game-events.component.css']
})
export class GameEventsComponent implements OnInit, OnDestroy {
  @ViewChild(EventsComponent, { static: true })
  eventsComponent: EventsComponent;

  gameTime = 0;
  event = {} as GameEvent;
  game: Game;
  teams: string[];
  players: string[];
  homeTeamPlayers: string[];
  awayTeamPlayers: string[];
  opponentPlayers: string[];

  filterablePlayers: Player[] = [];
  filteredPlayerId: string;

  shifts: Shift[] = [];

  penaltyDurations: PenaltyDuration[] = ['2', '2+2', '5', '10', '20'];
  strengthStates: StrengthState[] = [
    '5-5',
    '5-4',
    '4-5',
    '4-4',
    '5-3',
    '3-5',
    '4-3',
    '3-4',
    '3-3'
  ];
  oddMenRushDetails: OddMenRushDetail[] = [
    '4-3',
    '4-2',
    '4-1',
    '4-0',
    '3-2',
    '3-1',
    '3-0',
    '2-1',
    '2-0',
    '1-0'
  ];

  // TODO INT-4742 ensure completeness in test
  readonly allGameIncidentReasons: {
    key: GameIncidentReason;
    description: string;
  }[] = [
    { key: 'abuseOfOfficials', description: 'Abuse of Officials' },
    { key: 'boarding', description: 'Boarding' },
    { key: 'buttEnding', description: 'Butt-ending' },
    { key: 'charging', description: 'Charging' },
    { key: 'checkFromBehind', description: 'Check From Behind' },
    { key: 'checkToTheHeadOrNeck', description: 'Check To The Head or Neck' },
    { key: 'clipping', description: 'Clipping' },
    { key: 'divingOrEmbellishment', description: 'Diving or Embellishment' },
    { key: 'elbowing', description: 'Elbowing' },
    { key: 'highSticking', description: 'High-Sticking' },
    { key: 'interference', description: 'Interference' },
    { key: 'kicking', description: 'Kicking' },
    { key: 'kneeing', description: 'Kneeing' },
    { key: 'slewFooting', description: 'Slew-footing' },
    { key: 'spearing', description: 'Spearing' },
    { key: 'other', description: 'Other' }
  ];

  allOfficialsUncalledPenaltySeverities: {
    key: OfficialsUncalledPenaltySeverity;
    description: string;
  }[] = [
    { key: 'minor', description: 'Minor' },
    { key: 'major', description: 'Major' }
  ];

  allOfficialsPenaltyCallSeverities: {
    key: OfficialsPenaltyCallSeverity;
    description: string;
  }[] = [
    { key: 'unspecified', description: 'Unspecified' },
    { key: 'minor', description: 'Minor (2)' },
    { key: 'double-minor', description: 'Double Minor (2+2)' },
    { key: 'major', description: 'Major (5)' },
    { key: 'misconduct', description: 'Misconduct (10)' },
    { key: 'game-misconduct', description: 'Game Misconduct (20)' }
  ];

  allOfficialsCallActions: {
    key: OfficialsCallAction;
    description: string;
  }[] = [
    { key: 'call', description: 'Call' },
    { key: 'non-call', description: 'Non-Call' }
  ];

  allOfficialsCallAssessments: {
    key: OfficialsCallAssessment;
    description: string;
  }[] = [
    { key: 'unspecified', description: 'Unspecified' },
    { key: 'wrong', description: 'Wrong' }
  ];

  private componentDestroyed$: Subject<void> = new Subject();

  cameraAngle: CameraAngle;
  format: VideoFormat;
  variant: VideoVariantType;
  videoTrim: Video;

  @ViewChild('videoElement') videoElement: ElementRef;

  constructor(
    public eventService: EventService,
    private location: Location,
    private gameService: GameService,
    private eventValidationService: EventValidationService,
    private gameTimeService: GameTimeService,
    private puckPossessionStateService: PuckPossessionStateService,
    private strengthStateService: StrengthStateService,
    private shiftsService: ShiftsService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private logger: NGXLogger,
    private title: Title,
    private dialog: MatDialog,
    private broadcast: BroadcastService,
    private videoService: VideoService,
    private store: Store<GlobalState>,
    private gameEventInterruptionTypeService: GameEventInterruptionTypeService,
    private gameIncidentService: GameIncidentService
  ) {}

  ngOnInit(): void {
    const gameId = this.route.snapshot.params['gameId'];
    this.strengthStateService.init(gameId);
    this.gameTimeService.init(gameId);
    this.puckPossessionStateService.init(gameId);
    this.initForGame((this.route.snapshot.data as any).game);
    this.subscribeToReduxEvents();
    this.startWallClock();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(null);
  }

  private subscribeToReduxEvents() {
    this.event.videoTime = 0;
    this.store
      .select(selectGameId)
      .pipe(takeUntil(this.componentDestroyed$), take(1))
      .subscribe(async (gameId) => {
        if (gameId !== this.game._id) {
          this.logger.info(
            'reset redux state for game switch',
            gameId,
            this.game._id
          );
          await this.resetHard();
        }
      });
    this.store
      .select(selectEventType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(async (newValue) => {
        this.event.eventType = newValue;
        await this.handleDraftEvent();
      });
    this.store
      .select(selectPeriod)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.period = newValue));
    this.store
      .select(selectOddMenRushDetail)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.oddMenRushDetail = newValue));
    this.store
      .select(selectTeam)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(async (newValue) => {
        this.handleTeamMessage(newValue);
        await this.handleDraftEvent();
      });
    this.store
      .select(selectVideoTime)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => {
        this.updateVideoTime(newValue);
      });
    this.store
      .select(selectStrengthState)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((strengthState) => (this.event.strengthState = strengthState));
    this.store
      .select(selectGameTime)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((gameTime) => this.updateGameTime(gameTime));
    this.store
      .select(selectInterruption_type)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.interruption_type = newValue));
    this.store
      .select(selectGameIncidentReason)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => {
        this.event.gameIncidentReason = newValue;
        this.onGameIncidentChange();
      });
    this.store
      .select(selectVideoTag)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.videoTag = newValue));
    this.store
      .select(selectShotOutcome)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(async (newValue) => {
        this.event.shotOutcome = newValue as any;
        await this.handleDraftEvent();
      });
    this.store
      .select(selectNetImpact)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.netImpactX = newValue?.netImpactX));
    this.store
      .select(selectNetImpact)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.netImpactY = newValue?.netImpactY));
    this.store
      .select(selectShotScenario)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.shotScenario = newValue));
    this.store
      .select(selectShotType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.shotType = newValue));
    this.store
      .select(selectHas_net_traffic)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => {
        if (newValue !== undefined) {
          return (this.event.has_net_traffic = newValue === '1');
        }
      });
    this.store
      .select(selectHas_screen)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => {
        if (newValue !== undefined) {
          return (this.event.has_screen = newValue === '1');
        }
      });
    this.store
      .select(selectTeamFaceOffOutcome)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.teamFaceOffOutcome = newValue));
    this.store
      .select(selectPassType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.pass_type = newValue));
    this.store
      .select(selectPassOutcome)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.pass_outcome = newValue));
    this.store
      .select(selectPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.playerNumber = newValue));
    this.store
      .select(selectPassReceiverPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.pass_receiver = newValue));
    this.store
      .select(selectShotBlockerPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.blocker = newValue));
    this.store
      .select(selectDeflectorPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.deflector = newValue));
    this.store
      .select(selectScreenerPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.screener = newValue));
    this.store
      .select(selectNetTrafficCauserPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.net_traffic_causer = newValue));
    this.store
      .select(selectFaceOffOpponentPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.faceoff_opponent = newValue));
    this.store
      .select(selectFouledPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.fouled_player = newValue));
    this.store
      .select(selectPenaltyType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(async (newValue) => {
        this.event.penaltyType = newValue;
        if (newValue === 'expiration') {
          await this.handleDraftEvent();
        }
      });
    this.store
      .select(selectPosition)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((position) => {
        if (position) {
          if (position.x && position.y) {
            this.event.xPosition = position.x;
            this.event.yPosition = position.y;
          }
          // when both null set in order to clear
          if (!position.x && !position.y) {
            this.event.xPosition = position.x;
            this.event.yPosition = position.y;
          }
        }
      });
    this.store
      .select(selectPassReceiverPosition)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((position) => {
        if (position) {
          if (position.x && position.y) {
            this.event.receiverXPosition = position.x;
            this.event.receiverYPosition = position.y;
          }
          // when both null set in order to clear
          if (!position.x && !position.y) {
            this.event.receiverXPosition = position.x;
            this.event.receiverYPosition = position.y;
          }
        }
      });
    this.store
      .select(selectFaceoffOutcomePosition)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((position) => {
        if (position) {
          if (position.x && position.y) {
            this.event.faceoffOutcomeXPosition = position.x;
            this.event.faceoffOutcomeYPosition = position.y;
          }
          // when both null set in order to clear
          if (!position.x && !position.y) {
            this.event.faceoffOutcomeXPosition = position.x;
            this.event.faceoffOutcomeYPosition = position.y;
          }
        }
      });
    this.store
      .select(selectShotBlockerPosition)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((position) => {
        if (position) {
          if (position.x && position.y) {
            this.event.blockerXPosition = position.x;
            this.event.blockerYPosition = position.y;
          }
          // when both null set in order to clear
          if (!position.x && !position.y) {
            this.event.blockerXPosition = position.x;
            this.event.blockerYPosition = position.y;
          }
        }
      });
    this.store
      .select(selectHighlightType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.highlightType = newValue));
    this.store
      .select(selectHighlightPlayback)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.highlightPlayback = newValue));
    this.store
      .select(selectHighlightRating)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.highlightRating = newValue));
    this.store
      .select(selectPenaltyId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.penaltyId = newValue));
    this.store
      .select(selectPenaltyDuration)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.penaltyDuration = newValue));
    this.store
      .select(selectAssist1PlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.assist1 = newValue));
    this.store
      .select(selectAssist2PlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.event.assist2 = newValue));

    this.broadcast
      .listen()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(async (message) => {
        this.logger.info('broadcast message received', message.data);
        switch (message.data.type) {
          case BroadcastAction.ResetState:
            await this.reset(false);
            break;
          case BroadcastAction.Save:
            const successful = await this.save(false);
            if (successful) {
              await this.reset();
            }
            break;
          default:
            break;
        }
      });
  }

  private startWallClock() {
    if (
      this.game.lagClock &&
      (this.game.status === 'in_collection' ||
        this.game.status === 'in_extended_collection')
    ) {
      interval(1000)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          this.gameTimeService.updateElapsedTime(this.event.videoTime);
        });
    }
  }

  private updateVideoTime(newVideoTime: number) {
    this.event.videoTime = newVideoTime;
    this.gameTimeService.updatePeriodAndGameTime(newVideoTime);
    this.puckPossessionStateService.updatePuckPossessionState(newVideoTime);
    this.strengthStateService.updateStrengthState(newVideoTime);
  }

  private updateGameTime(newGameTime: number) {
    this.gameTime = newGameTime;
    this.event.gameTime = newGameTime;
    this.strengthStateService.updateActivePenalties(newGameTime);
    this.shiftsService.updateActiveShifts(newGameTime, this.shifts);
  }

  async saveManually() {
    const successful = await this.save(true);
    if (successful) {
      await this.reset();
    }
  }

  async resetManually() {
    await this.reset();
  }

  private async reset(emitReset: boolean = true) {
    this.resetSharedState(this.event, emitReset);
    await this.resetMode();
  }

  private async resetMode() {
    this.location.replaceState(`/games/${this.game._id}/events/`);
    const gameEventState = await firstValueFrom(
      this.store.select(selectGameEventState)
    );
    const gameState = await firstValueFrom(this.store.select(selectGameState));
    this.event = {
      ...gameEventState,
      gameId: gameState?.gameId,
      teamId:
        gameEventState?.team && gameEventState?.team !== 'neutral'
          ? this.getTeamId(gameEventState?.team)
          : null
    } as unknown as GameEvent;
  }

  private resetSharedState(e: GameEvent, emitReset: boolean = true) {
    const currentPeriod = e.period;
    const currentTeam = e.team;

    this.store.dispatch(resetStateGame({ gameId: this.game._id }));
    this.store.dispatch(
      resetStateGameEvent({ period: currentPeriod, team: currentTeam })
    );

    if (emitReset) {
      this.broadcast.postMessage({ type: BroadcastAction.ResetState });
    }
  }

  async save(ignoreWarnings: boolean): Promise<boolean> {
    if (!this.event || !this.event.eventType) {
      // prevent error message on startup
      return false;
    }

    if (this.event.eventType === 'shot') {
      if (this.event.has_net_traffic === undefined) {
        this.event.has_net_traffic = false;
      }
      if (this.event.has_screen === undefined) {
        this.event.has_screen = false;
      }
      if (this.event.isAwayTeamEmptyNet === undefined) {
        this.event.isAwayTeamEmptyNet = false;
      }
      if (this.event.isHomeTeamEmptyNet === undefined) {
        this.event.isHomeTeamEmptyNet = false;
      }
    }

    if (this.event.team && this.event.team !== 'neutral') {
      this.event.teamId = this.getTeamId(this.event.team);
    } else {
      this.event.teamId = null;
    }

    if (this.event.playerNumber && this.event.teamId) {
      this.event.playerId = this.getPlayerId(
        this.event.playerNumber,
        this.event.teamId
      );
    } else {
      this.event.playerNumber = null;
      this.event.playerId = null;
    }

    if (this.event.assist1 && this.event.teamId) {
      this.event.assist1Id = this.getPlayerId(
        this.event.assist1,
        this.event.teamId
      );
    } else {
      this.event.assist1 = null;
      this.event.assist1Id = null;
    }

    if (this.event.assist2 && this.event.teamId) {
      this.event.assist2Id = this.getPlayerId(
        this.event.assist2,
        this.event.teamId
      );
    } else {
      this.event.assist2 = null;
      this.event.assist2Id = null;
    }

    if (this.event.eventType === 'interruption') {
      if (this.event.interruption_type === 'period_start') {
        if (['1', '2', '3'].includes(this.event.period)) {
          this.event.gameTime = (parseInt(this.event.period, 10) - 1) * 20 * 60;
        }
      }
      if (this.event.interruption_type === 'period_end') {
        if (['1', '2', '3'].includes(this.event.period)) {
          this.event.gameTime = parseInt(this.event.period, 10) * 20 * 60;
        }
      }
    }

    const isCreatingLiveDraftEvents = this.game.isLiveDraftEvents;
    const isAllowedDraftEvent = this.eventService.isAllowedDraftEvent(
      this.event.eventType
    );
    const errorMessage = this.eventValidationService.validate(
      this.event,
      this.game
    );
    if (errorMessage) {
      if (
        this.event._id ||
        !(isCreatingLiveDraftEvents && isAllowedDraftEvent)
      ) {
        this.broadcast.postMessage({
          type: BroadcastAction.SaveFailed,
          error: errorMessage
        });
        this.alertService.showError('Event is not valid: ' + errorMessage);
        return false;
      } else {
        this.logger.info('Event is draft and invalid: ' + errorMessage);
      }
    }

    let hasBeenDraft = false;
    if (this.event._id && this.event.draft && !errorMessage) {
      hasBeenDraft = true;
      this.event.draft = false;
    } else {
      if (errorMessage && isCreatingLiveDraftEvents && isAllowedDraftEvent) {
        this.event.draft = true;
      }
    }

    this.event.gameId = this.game._id;
    if (
      (!this.event._id &&
        this.event.eventType === 'face_off' &&
        !this.event.draft) ||
      (this.event._id && hasBeenDraft && this.event.eventType === 'face_off')
    ) {
      const otherEvent = this.createCorrespondingEventForOtherTeam();
      try {
        const e = await this.saveSingleEvent(otherEvent, ignoreWarnings);
        this.eventsComponent.addEvent(e);
      } catch (error) {
        this.broadcast.postMessage({
          type: BroadcastAction.SaveFailed,
          error: errorMessage
        });
        this.alertService.showError(
          'Saving opponent event failed: ' + error.message
        );
        return false;
      }
    }
    const eventDeepCopy = JSON.parse(JSON.stringify(this.event));
    try {
      const e = await this.saveSingleEvent(eventDeepCopy, ignoreWarnings);
      this.eventsComponent.addEvent(e);
    } catch (error) {
      this.broadcast.postMessage({
        type: BroadcastAction.SaveFailed,
        error: error.message
      });
      this.alertService.showError('Saving event failed: ' + error.message);
      return false;
    }

    return true;
  }

  private createCorrespondingEventForOtherTeam() {
    this.event.faceoffId = ObjectId();
    const otherEvent = JSON.parse(JSON.stringify(this.event));
    delete otherEvent._id; // draft event contains _id
    if (this.event.faceoff_opponent != null) {
      this.updateFaceOffDetails(otherEvent);
    }
    if (this.game != null) {
      otherEvent.team =
        this.event.team === this.game.homeTeam
          ? this.game.awayTeam
          : this.game.homeTeam;
      otherEvent.teamId = this.getTeamId(otherEvent.team);
      otherEvent.playerId = this.getPlayerId(
        otherEvent.playerNumber,
        otherEvent.teamId
      );
    }
    this.logger.info('other event: ' + otherEvent.toString());
    return otherEvent;
  }

  private updateFaceOffDetails(otherEvent: GameEvent) {
    otherEvent.playerNumber = this.event.faceoff_opponent;
    otherEvent.faceoff_opponent = this.event.playerNumber;
    if (this.event.teamFaceOffOutcome === 'win') {
      otherEvent.teamFaceOffOutcome = 'lost';
    } else if (this.event.teamFaceOffOutcome === 'lost') {
      otherEvent.teamFaceOffOutcome = 'win';
    }
  }

  private async saveSingleEvent(
    event: GameEvent,
    ignoreWarnings: boolean
  ): Promise<GameEvent> {
    const warnings = this.eventValidationService.getWarnings(event, this.game);
    if (warnings && !ignoreWarnings) {
      throw new Error(warnings);
    }

    // purge attributes that should not be saved or exported
    delete event['penalties'];
    delete event['videoStatus'];
    delete event['isInterrupted'];

    return this.eventService
      .save(event)
      .pipe(
        tap((e) => {
          this.broadcast.postMessage({ type: BroadcastAction.SaveComplete });
          this.alertService.showInfo('Event saved');
          if (event.eventType === 'penalty') {
            this.strengthStateService.addAndDispatchActivePenalty(
              e,
              e.gameTime
            );
          } else if (
            event.eventType === 'face_off' ||
            event.eventType === 'interruption'
          ) {
            this.gameTimeService.addAndDispatchTimeEvent(e, e.videoTime);
          } else if (event.eventType === 'puckPossession') {
            this.puckPossessionStateService.addAndDispatchPuckPossessionEvent(
              e,
              e.videoTime
            );
          }
        })
      )
      .toPromise();
  }

  private getTeamId(teamName): string {
    if (this.game.homeTeam === teamName) {
      return this.game.homeTeamId;
    } else if (this.game.awayTeam === teamName) {
      return this.game.awayTeamId;
    } else {
      throw new Error('Could not find team for name: ' + teamName);
    }
  }

  private getPlayerId(playerNumber, teamId): string {
    const homeTeamOrAwayTeam = teamId === this.game.homeTeamId;
    return this.game.getPlayerIdByJerseyNumber(
      homeTeamOrAwayTeam,
      playerNumber
    );
  }

  editEvent(event) {
    this.eventService
      .getEvent(this.game._id, event._id)
      .subscribe((pbpEvent) => this.editSavedEvent(pbpEvent));
  }

  seekEvent(event: GameEvent) {
    this.event.videoTime = event.videoTime;
    this.store.dispatch(
      videoTimeExternalChange({
        videoTimeExternal: event.videoTime,
        random: self.crypto.randomUUID()
      })
    );
  }

  editSavedEvent(event: GameEvent) {
    this.event = event;
    this.location.replaceState(
      `/games/${this.game._id}/events/${this.event._id}`
    );

    this.store.dispatch(eventIdChange({ _id: event._id }));
    this.store.dispatch(gameIdChange({ gameId: event.gameId }));
    this.store.dispatch(eventTypeChange({ eventType: event.eventType }));
    this.store.dispatch(
      oddMenRushDetailChange({ oddMenRushDetail: event.oddMenRushDetail })
    );
    this.store.dispatch(
      videoTimeExternalChange({
        videoTimeExternal: event.videoTime,
        random: self.crypto.randomUUID()
      })
    );
    this.store.dispatch(teamChange({ team: event.team }));
    this.store.dispatch(
      playerNumberChange({ playerNumber: event.playerNumber })
    );
    this.store.dispatch(
      teamFaceoffOutcomeChange({ teamFaceOffOutcome: event.teamFaceOffOutcome })
    );

    this.store.dispatch(
      faceoffOpponentPlayerNumberChange({
        faceOffOpponentPlayerNumber: event.faceoff_opponent
      })
    );

    this.store.dispatch(shotOutcomeChange({ shotOutcome: event.shotOutcome }));
    this.store.dispatch(
      shotScenarioChange({ shotScenario: event.shotScenario })
    );
    this.store.dispatch(shotTypeChange({ shotType: event.shotType }));

    this.store.dispatch(
      deflectorPlayerNumberChange({ deflectorPlayerNumber: event.deflector })
    );

    this.store.dispatch(
      assist1PlayerNumberChange({
        assist1PlayerNumber: event.assist1
      })
    );

    this.store.dispatch(
      assist2PlayerNumberChange({
        assist2PlayerNumber: event.assist2
      })
    );

    // this.store.dispatch(isBlockedChange({ is_blocked: event.is_blocked}));  // TODO remove unused field!
    this.store.dispatch(
      shotBlockerPlayerNumberChange({ shotBlockerPlayerNumber: event.blocker })
    );

    this.store.dispatch(
      hasNetTrafficChange({
        has_net_traffic: event.has_net_traffic ? '1' : '0'
      })
    );

    this.store.dispatch(
      netTrafficCauserPlayerNumberChange({
        netTrafficCauserPlayerNumber: event.net_traffic_causer
      })
    );
    this.store.dispatch(
      hasScreenChange({ has_screen: event.has_screen ? '1' : '0' })
    );

    this.store.dispatch(
      screenerPlayerNumberChange({ screenerPlayerNumber: event.screener })
    );

    this.store.dispatch(
      fouledPlayerNumberChange({ fouledPlayerNumber: event.fouled_player })
    );

    this.store.dispatch(passTypeChange({ passType: event.pass_type }));

    this.store.dispatch(
      passReceiverPlayerNumberChange({
        passReceiverPlayerNumber: event.pass_receiver
      })
    );

    this.store.dispatch(passOutcomeChange({ passOutcome: event.pass_outcome }));

    this.store.dispatch(
      interruptionTypeChange({ interruption_type: event.interruption_type })
    );

    this.store.dispatch(videoTagChange({ videoTag: event.videoTag }));

    this.store.dispatch(periodChange({ period: event.period }));
    this.store.dispatch(
      highlightTypeChange({ highlightType: event.highlightType })
    );
    this.store.dispatch(
      highlightPlaybackChange({ highlightPlayback: event.highlightPlayback })
    );
    this.store.dispatch(
      highlightRatingChange({ highlightRating: event.highlightRating })
    );
    this.store.dispatch(
      gameIncidentReasonChange({ gameIncidentReason: event.gameIncidentReason })
    );
    if (event.xPosition !== null && event.yPosition !== null) {
      this.store.dispatch(
        positionChange({ position: { x: event.xPosition, y: event.yPosition } })
      );
    }
    if (event.receiverXPosition !== null && event.receiverYPosition !== null) {
      this.store.dispatch(
        passReceiverPositionChange({
          passReceiverPosition: {
            x: event.receiverXPosition,
            y: event.receiverYPosition
          }
        })
      );
    }
    if (event.blockerXPosition !== null && event.blockerYPosition !== null) {
      this.store.dispatch(
        shotBlockerPositionChange({
          shotBlockerPosition: {
            x: event.blockerXPosition,
            y: event.blockerYPosition
          }
        })
      );
    }
    if (
      event.faceoffOutcomeXPosition !== null &&
      event.faceoffOutcomeYPosition !== null
    ) {
      this.store.dispatch(
        faceoffOutcomePositionChange({
          faceoffOutcomePosition: {
            x: event.faceoffOutcomeXPosition,
            y: event.faceoffOutcomeYPosition
          }
        })
      );
    }
  }

  onTeamChange(team: string): void {
    if (!team) {
      return;
    }

    if (this.game.homeTeam === team) {
      this.players = Players.orderPlayerStringByJerseyNumber(
        this.homeTeamPlayers
      );
      this.opponentPlayers = Players.orderPlayerStringByJerseyNumber(
        this.awayTeamPlayers
      );
    } else if (this.game.awayTeam === team) {
      this.players = Players.orderPlayerStringByJerseyNumber(
        this.awayTeamPlayers
      );
      this.opponentPlayers = Players.orderPlayerStringByJerseyNumber(
        this.homeTeamPlayers
      );
    }

    // switch player to same jersey number of selected team
    if (this.event.playerNumber) {
      if (!this.players.includes(this.event.playerNumber)) {
        const [jerseyNumber, _] = this.event.playerNumber.split(' - ');
        this.event.playerNumber = this.gameService.findPlayerByJerseyNumber(
          this.game,
          team,
          jerseyNumber
        );
        this.store.dispatch(
          playerNumberChange({ playerNumber: this.event.playerNumber })
        );
      }
    }
  }

  onPeriodChange(period: GamePeriod) {
    this.event.period = period;
    this.store.dispatch(periodChange({ period }));
  }

  private handleTeamMessage(newValue) {
    if (newValue == null) {
      return;
    }
    this.event.team = newValue;
    this.onTeamChange(this.event.team);
  }

  private initForGame(game: Game) {
    if (game && game._id) {
      this.game = game;
      this.event.gameId = game._id;
      this.players = Players.orderPlayerStringByJerseyNumber(
        this.gameService.getAllPlayers(game)
      );
      this.homeTeamPlayers = Players.orderPlayerStringByJerseyNumber(
        this.gameService.getHomeTeamPlayers(game)
      );
      this.awayTeamPlayers = Players.orderPlayerStringByJerseyNumber(
        this.gameService.getAwayTeamPlayers(game)
      );
      this.filterablePlayers = game.getAllPlayersObj();
      this.teams = this.gameService.getTeamNames(game);
      this.teams.push(Team.NEUTRAL_TEAM);
      this.title.setTitle(
        this.game.homeTeam + '-' + this.game.awayTeam + ' > Game Actions'
      );

      this.shiftsService
        .getShifts(game)
        .subscribe((shifts) => (this.shifts = shifts));
      this.videoService.prepareMediaData(this.game.videos ?? []);
    } else {
      this.players = [];
      this.homeTeamPlayers = [];
      this.awayTeamPlayers = [];
      this.teams = [];
    }
  }

  isGameSet() {
    return this.game != null;
  }

  prepareVideoPlayerTrimDialog(goalClip: GoalClip) {
    this.videoTrim = this.findVideoForTrimming(goalClip);
    if (!this.videoTrim) {
      this.alertService.showError(
        `Couldn't find a video to trim. Requires a video with camera angle "tvfeed" or "main".`
      );
    }
    this.cameraAngle = this.videoTrim?.cameraAngle;
    this.format = this.videoTrim?.format;
    this.variant = this.videoTrim?.variant;
  }

  openVideoPlayerTrimDialog(duration: number) {
    const cameraIndex = this.findVideoCameraIndex();
    const data = this.prepareDataForTrim(duration);
    this.videoTrim = null;
    const dialogRef = this.dialog.open(VideoPlayerTrimDialogComponent, {
      width: '1200px',
      height: '850px',
      data: {
        game: this.game,
        cameraIndex,
        src: data.videoUrl,
        startOfVideoClip: data.startOfExtendedVideoClip,
        durationVideoClip: data.durationOfExtendedVideoClip,
        startOfOriginalVideoClip: data.startOfOriginalVideoClip,
        durationOfOriginalVideoClip: data.durationOfOriginalVideoClip,
        durationOfFullVideo: duration
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.startVideoTime && result.endVideoTime) {
          if (!this.event.goalClip) {
            this.event.goalClip = { exportStatus: { status: 'not_started' } };
          }
          this.event.goalClip.startVideoTime = result.startVideoTime;
          this.event.goalClip.endVideoTime = result.endVideoTime;
          this.event.goalClip.cameraAngle = this.cameraAngle;
          this.event.goalClip.format = this.format;
          this.event.goalClip.variant = this.variant;
        } else if (result.cameraAngle) {
          // switch camera
          this.cameraAngle = result.cameraAngle;
          this.prepareVideoPlayerTrimDialog(this.event.goalClip);
        }
      }
    });
  }

  errorVideoPlayerTrimDialog(error: string) {
    this.videoTrim = null;
    const videos = this.game?.videos;
    const dialogRef = this.dialog.open(CameraAnglesSelectDialogComponent, {
      width: '550px',
      data: {
        message: `Video issue with ${this.cameraAngle}, try other camera angle`,
        videos
      }
    });
    this.logger.error('Could not trim video', error);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.cameraAngle) {
        this.cameraAngle = result.cameraAngle;
        this.prepareVideoPlayerTrimDialog(this.event.goalClip);
      }
    });
  }

  prepareDataForTrim(duration: number) {
    const { startTimeOfGoalSlowSequence, durationOfSlowSequence } =
      this.getGoalSlowSequence();
    // Duration of whole video is important for achieving extended time before and after sequence
    const durationOfFullVideo = duration;

    return {
      startOfOriginalVideoClip: startTimeOfGoalSlowSequence,
      durationOfOriginalVideoClip: durationOfSlowSequence,
      ...this.videoService.prepareVideoDataForTrimming(
        this.videoTrim.urlSigned,
        startTimeOfGoalSlowSequence,
        durationOfSlowSequence,
        durationOfFullVideo
      )
    };
  }

  getGoalSlowSequence() {
    if (
      this.event?.goalClip?.startVideoTime &&
      this.event?.goalClip?.endVideoTime
    ) {
      return {
        startTimeOfGoalSlowSequence: this.event.goalClip.startVideoTime,
        durationOfSlowSequence:
          this.event.goalClip.endVideoTime - this.event.goalClip.startVideoTime
      };
    } else {
      /**
       * Calculate desired start time of goal slow sequence
       * by addition to videoTime of OFFSET of Camera Angle and aprox. prediction
       * of GOAL_SLOW_SEQUENCE_TIME_START
       */
      // We always target aprox. prediction of DEFAULT_DURATION
      return {
        startTimeOfGoalSlowSequence:
          this.event.videoTime +
          (this.videoTrim.offset ?? 0) +
          VideoService.GOAL_SLOW_SEQUENCE_TIME_START,
        durationOfSlowSequence: VideoService.DEFAULT_DURATION
      };
    }
  }

  showSummary(): void {
    this.dialog.open(PlayerSummaryComponent, {
      width: '640px',
      data: {
        game: this.game
      }
    });
  }

  updateEvent(event: GameEvent) {
    if (event.eventType === 'penalty') {
      this.strengthStateService.addAndDispatchActivePenalty(
        event,
        this.gameTime
      );
      this.strengthStateService.updateStrengthState(this.event.videoTime);
    }

    if (event.eventType === 'face_off' || event.eventType === 'interruption') {
      this.gameTimeService.addAndDispatchTimeEvent(event, this.event.videoTime);
    } else if (event.eventType === 'puckPossession') {
      this.puckPossessionStateService.addAndDispatchPuckPossessionEvent(
        event,
        this.event.videoTime
      );
    }

    if (event.eventType === 'time_on_ice') {
      const shiftOn = this.shifts.find((s) => s.onEvent._id === event._id);
      const shiftOff = this.shifts.find(
        (s) => s.offEvent && s.offEvent._id === event._id
      );

      if (shiftOn || shiftOff) {
        // existing shift - TOI event has been updated
        this.shiftsService.updateActiveShifts(this.gameTime, this.shifts);
        return;
      }

      if (event.timeOnIceType === 'on') {
        const shift = {
          player: this.game.getPlayerObj(event.playerNumber, event.team),
          onEvent: event,
          offEvent: null,
          duration: null
        } as Shift;
        this.shifts.push(shift);
      } else if (event.timeOnIceType === 'off') {
        const shift = this.shifts.find(
          (s) =>
            s.onEvent.playerId === event.playerId &&
            !s.offEvent &&
            s.onEvent.period === event.period &&
            s.onEvent.gameTime <= event.gameTime
        );
        if (!shift) {
          this.logger.warn(
            'Could not find existing shift for TOI off event',
            event
          );
          return;
        }
        shift.offEvent = event;
        shift.duration = event.gameTime - shift.onEvent.gameTime;
      }

      this.shiftsService.updateActiveShifts(this.gameTime, this.shifts);
    }
  }

  ratingChange(rating: HighlightRating) {
    this.event.highlightRating = rating;
  }

  resetHighlightData() {
    this.event.highlightPlayback = null;
    this.event.highlightRating = null;
  }

  onEventTypeChange(eventType: EventType) {
    this.event.eventType = eventType;
    this.resetSharedState(this.event);
  }

  get isEditMode() {
    return !!this.event._id;
  }

  async savingDraftEvent(): Promise<void> {
    this.logger.info(`Create live draft event ${this.event.eventType}`);
    const successful = await this.save(false);
    if (successful) {
      await this.reset();
    }
  }

  async handleDraftEvent(): Promise<void> {
    if (
      this.eventService.shouldSaveDraftEvent(
        this.isEditMode,
        this.game.isLiveDraftEvents,
        this.event
      )
    ) {
      await this.savingDraftEvent();
    }
  }

  exportGameEvent(event: GameEvent) {
    event.goalClip.exportStatus = {
      status: 'pending',
      timeoutAt: new Date(Date.now() + 10 * 60 * 1000)
    };
    this.eventService.exportGoalClips(event.gameId, [event._id]).subscribe({
      next: ({ message }) => {
        this.alertService.showInfo(message);
      },
      error: (httpError) => {
        this.alertService.showError(
          'Export goal clip failed: ' + httpError.error.message
        );
        event.goalClip.exportStatus = undefined;
      }
    });
  }

  findVideoForTrimming(goalClip: GoalClip) {
    const format = this.format ?? goalClip?.format;
    const variant = this.variant ?? goalClip?.variant;
    const cameraAngle = this.cameraAngle ?? goalClip?.cameraAngle;

    if (cameraAngle) {
      // already saved goal - find video
      if (format && variant) {
        return this.game.videos.find(
          (v) =>
            v.cameraAngle === cameraAngle &&
            v.format === format &&
            v.variant === variant
        );
      }
      // switching between cameras for unsaved goal
      return this.game.videos.find((v) => v.cameraAngle === cameraAngle);
    }

    // default behavior
    const tvFeed = this.game.videos.find(
      (v) => v.cameraAngle === CameraAngle.TV_FEED
    );
    if (tvFeed) {
      return tvFeed;
    }

    const main = this.game.videos.find(
      (v) => v.cameraAngle === CameraAngle.MAIN
    );
    if (main) {
      return main;
    }

    return;
  }

  findVideoCameraIndex() {
    if (this.format && this.variant) {
      return this.game.videos.findIndex(
        (v) =>
          v.cameraAngle === this.cameraAngle &&
          v.format === this.format &&
          v.variant === this.variant
      );
    } else {
      return this.game.videos.findIndex(
        (v) => v.cameraAngle === this.cameraAngle
      );
    }
  }

  private async resetHard() {
    this.resetHardSharedState();
    await this.resetMode();
  }

  private resetHardSharedState() {
    this.store.dispatch(resetHardStateGame({ gameId: this.game._id }));
    this.store.dispatch(resetHardStateGameEvent());
    this.broadcast.postMessage({ type: BroadcastAction.ResetState });
  }

  get interruptionReasons(): InterruptionType[] {
    return this.gameEventInterruptionTypeService.allInterruptionTypes;
  }

  getInterruptionReasonLabel(interruptionType: InterruptionType) {
    return this.gameEventInterruptionTypeService.getInterruptionTypeLabel(
      interruptionType
    );
  }

  isGameIncidentAllowed() {
    return this.game.isGameIncidentCollection;
  }

  isShowGameIncidentUncalledPenaltySeverity() {
    return (
      this.isGameIncidentAllowed() &&
      this.gameIncidentService.isWrongUncalledPenaltyEvent(this.event)
    );
  }

  isShowGameIncidentAdditionalAttributes() {
    return (
      this.isGameIncidentAllowed() &&
      this.gameIncidentService.isWithReason(this.event)
    );
  }

  isShowGameIncidentCalledPenaltySeverity() {
    return (
      this.isGameIncidentAllowed() &&
      this.gameIncidentService.isCalledPenaltyEvent(this.event)
    );
  }

  onGameIncidentChange() {
    this.gameIncidentService.updateUncalledPenaltySeverity(this.event);
    this.gameIncidentService.updateCalledPenaltySeverity(this.event);
    this.gameIncidentService.applyDefaultValues(this.event);
  }

  isTeamDisabled() {
    const teamDisabledEventTypes: EventType[] = ['game_incident'];
    return teamDisabledEventTypes.includes(this.event?.eventType);
  }

  isPlayerDisabled() {
    const playerDisabledEventTypes: EventType[] = ['game_incident'];
    return playerDisabledEventTypes.includes(this.event?.eventType);
  }

  isIceRinkVisible() {
    const iceRinkNotVisibleEventTypes: EventType[] = ['game_incident'];
    return !iceRinkNotVisibleEventTypes.includes(this.event?.eventType);
  }
}
